
.swiper-container {
    position: relative;
    padding-bottom: 24px;
}
::v-deep() {
    .swiper-pagination {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        &-bullet {
            width: 6px;
            height: 6px;
            margin: 0 2px;
            border: 0.5px solid var(--v-primary-base);
            &-active {
                background-color: var(--v-primary-base);
            }
        }
    }
}
.swiper-button-prev,
.swiper-button-next {
    display: none;
}

@media (min-width: 1024px) {
    .swiper-container {
        padding-bottom: 0;
        padding: 0 48px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 48px;
            height: 100%;
            background: #fff;
            z-index: 2;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
        width: 32px;
        height: 32px;
        background-color: var(--v-primary-base);
        border-radius: 50%;
        margin: 0;
        transform: translateY(-50%);
        &::after {
            display: none;
        }
        .v-icon {
            color: #fff;
        }
        &.swiper-button-disabled {
            opacity: 1;
            background-color: var(--v-grey-lighten3);
        }
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
}
