
.v-data-table {
    border-top: 2px solid var(--v-primary-base);
    tr {
        border-bottom: 1px solid var(--border-color);
        > th {
            width: 25%;
            border-right: 1px solid var(--border-color);
        }
    }
}
@media (min-width: 1024px) {
    .v-data-table {
        tr {
            > th {
                width: 18%;
            }
        }
    }
}
