
.review-thumb {
    position: relative;
    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }
}
