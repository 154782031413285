
::v-deep {
    .item-disabled {
        text-decoration: line-through;
        opacity: 0.5;
        pointer-events: none;
    }
    .v-data-table__mobile-row {
        display: table-cell !important;
    }
}
