
.product-intro {
    position: relative;
    height: auto;
    &__info {
        position: sticky;
        top: 0;
    }
}
::v-deep {
    .v-slide-group__prev {
        display: none !important;
    }
}
