
.product-intro-thumb-slide {
    .swiper-container {
        padding: 0 32px;
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: 24px !important;
        height: calc(100% + 2px) !important;
        margin-top: 0;
        border-radius: 0;
        background-color: #fff !important;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        .v-icon {
            color: var(--v-grey-darken4);
        }
        &.swiper-button-disabled {
            opacity: 1 !important;
            .v-icon {
                opacity: 0.35;
            }
        }
    }
}

.product-image {
    max-width: calc(100vw - var(--grid-gutter) * 2);
}
